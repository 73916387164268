import { useEffect, useState } from "react";

// {
//   "amount": {
//     "value": "30.00",
//     "currency": "RUB"
//   },
//   "billId": "ed89dd9a-a73e-492c-8498-ff42c88ba1cd",
//   "payUrl": "https://oplata.qiwi.com/form/?invoice_uid=3a45fe62-de63-43a6-ad11-d57b6243f242",
//   "siteId": "5s2dka-00",
//   "status": {
//     "value": "WAITING",
//     "changedDateTime": "2023-02-04T19:17:50.29+03:00"
//   },
//   "customer": {
//     "email": "zeinell69@gmail.com"
//   },
//   "customFields": {
//     "apiClient": "php_sdk",
//     "apiClientVersion": "0.2.2"
//   },
//   "creationDateTime": "2023-02-04T19:17:50.29+03:00",
//   "expirationDateTime": "2023-03-21T19:17:50.29+03:00"
// }

export default function PaymentTimelineItem(props) {
  const [whatToDoText, setWhatToDoText] = useState("");
  const [whatHappeningText, setWhatHappeningText] = useState("");

  useEffect(() => {
    if (props.data.status.value === "PAID" || props.data.status === "succeeded") {
      setWhatToDoText("Поздравляем! ");
      setWhatHappeningText(
        "Ваш заказ на сумму " + props.data.amount.value + " " + props.data.amount.currency + " успешно оплачен."
      );
    } else {
      setWhatToDoText("Подождите, ");
      setWhatHappeningText("выполняется процесс оплаты...");
    }
  }, [props.data.amount.currency, props.data.amount.value, props.data.status.value]);

  return (
    <div>
      {props.data.status.value === "PAID" || props.data.status === "succeeded" ? (
        <p>
          ✅ {whatToDoText}
          {whatHappeningText}
        </p>
      ) : (
        <p>
          ⌛ {whatToDoText}
          {whatHappeningText}
        </p>
      )}
    </div>
  );
}
