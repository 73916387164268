import Api from "../models/Api";
import dataConstants  from "./dataServiceConstants";

class DataService {
    data = [];
    categories = [];

    preloadData(){
        return Api.getServices().then((_data) => {
            this.data = _data.data.data.data
          });
    }
    preloadCategories(){
        return Api.getSocialNetworks().then((_data) => {
            let result = []

            let allCategories = _data.data.data.data;

            for(let j = 0; j < allCategories.length; j++){
                result.push(allCategories[j].name)
            }
            this.categories = result;

          });
    }

    getData(){
        return this.data;
    }

    getAllCategories(){
        return this.categories;
    }

    getDescriptions(category){
        let categoryLower = category.toLowerCase()
        
        if(categoryLower.indexOf("tiktok") !== -1){
            return dataConstants.categoryDescriptions['tiktok'];
        }
        else if(categoryLower.indexOf("telegram") !== -1){
            return dataConstants.categoryDescriptions['telegram'];
        }
        else if(categoryLower.indexOf("индивидуальные услуги") !== -1){
            return dataConstants.categoryDescriptions['индивидуальные услуги'];
        }
        else if(categoryLower.indexOf("instagram") !== -1){
            return dataConstants.categoryDescriptions['instagram'];
        }
        else if(categoryLower.indexOf("youtube") !== -1){
            return dataConstants.categoryDescriptions['youtube'];
        }
        else if(categoryLower.indexOf("facebook") !== -1){
            return dataConstants.categoryDescriptions['facebook'];
        }
        else if(categoryLower.indexOf("вконтакте") !== -1){
            return dataConstants.categoryDescriptions['вконтакте'];
        }
        else if(categoryLower.indexOf("twitter") !== -1){
            return dataConstants.categoryDescriptions['twitter'];
        }
        else if(categoryLower.indexOf("twitch") !== -1){
            return dataConstants.categoryDescriptions['twitch'];
        }
    }

    getImage(category){
        let categoryLower = category.toLowerCase()
        
        if(categoryLower.indexOf("tiktok") !== -1){
            return "https://sf-tb-sg.ibytedtos.com/obj/eden-sg/uhtyvueh7nulogpoguhm/tiktok-icon2.png";
        }
        else if(categoryLower.indexOf("telegram") !== -1){
            return "https://upload.wikimedia.org/wikipedia/commons/5/5c/Telegram_Messenger.png";
        }
        else if(categoryLower.indexOf("индивидуальные услуги") !== -1){
            return "https://cdn-icons-png.flaticon.com/512/3790/3790202.png";
        }
        else if(categoryLower.indexOf("instagram") !== -1){
            return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/Instagram-Icon.png/1200px-Instagram-Icon.png";
        }
        else if(categoryLower.indexOf("youtube") !== -1){
            return "https://www.iconpacks.net/icons/2/free-youtube-logo-icon-2431-thumb.png";
        }
        else if(categoryLower.indexOf("facebook") !== -1){
            return "https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1200px-Facebook_Logo_%282019%29.png";
        }
        else if(categoryLower.indexOf("вконтакте") !== -1){
            return "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/VK_Compact_Logo_%282021-present%29.svg/2048px-VK_Compact_Logo_%282021-present%29.svg.png";
        }
        else if(categoryLower.indexOf("twitter") !== -1){
            return "https://cdn-icons-png.flaticon.com/512/889/889147.png";
        }
        else if(categoryLower.indexOf("twitch") !== -1){
            return "https://d15shllkswkct0.cloudfront.net/wp-content/blogs.dir/1/files/2015/04/twitch-logo.png";
        }
        else{
            return "https://logos-download.com/wp-content/uploads/2017/06/No_Logo.png";
        }
    }

    getFormData(type) 
    {
        switch(type){
            case "Default":
                return dataConstants.DefaultFormData;
            case "Package":
                return dataConstants.PackageFormData;
            case "Custom Comments":
                return dataConstants.CustomCommentsFormData;
            case "Custom Comments Package":
                return dataConstants.CustomCommentsFormData;
            case "Comment Likes":
                return dataConstants.CommentLikesFormData;
            case "Subscriptions":
                return dataConstants.SubscriptionsFormData;
            case "Price":
                return dataConstants.PriceFormData;
        }
    }
}

export default new DataService()