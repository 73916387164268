import React from "react";
import dataService from "../services/dataService";
import { Link as RouterLink } from "react-router-dom";
import { useState, useEffect } from "react";

const ServicesLinks = () => {
  const [categories, setCategories] = useState([]);

  useEffect(async () => {
    setCategories(dataService.getAllCategories())
  },[])

  return (
    <div className="flex flex-col min-h-screen">
      <div className="min-w-full mt-[70px] px-16 pt-[40px] m-8">
        <div className="block grid grid-cols-1 gap-y-8 md:grid-cols-1">


          {categories && 
          (categories.map((category) => {
            return (
                <RouterLink to={`/services/${category}`} key={category} style={{ textDecoration: 'none', color: 'black' }}>
                <article className="container mx-auto rounded-2xl shadow-md shadow-gray-500 flex flex-col md:flex-row overflow-hidden w-100 items-center bg-zinc-100">
                  <header className="">
                    <img
                      src={dataService.getImage(category)}
                      alt="imagem"
                      className="object-cover w-[500px]"
                    />
                  </header>
                  <main className="">
                    <h1 className="font-sans text-2xl font-bold text-center">
                      {category}
                    </h1>
                    <p className="text-justify">
                      {dataService.getDescriptions(category)}
                    </p>
                  </main>
                </article>
              </RouterLink>
            );})
          )}

          
        </div>
      </div>
    </div>
  );
};

export default ServicesLinks;
