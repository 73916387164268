import React from "react";

import { Link as RouterLink } from "react-router-dom";

// Понадобится при подключении соц сетей
// import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="w-full bg-slate-900 text-gray-300 py-4 px-2">
      <div className="max-w-[1240px] flex flex-row justify-between mx-auto border-b-2 border-gray-600">
        <div className="col-span-2 pt-2 md:pt-2 py-2">
          <ul>
            <li className="p-2">
              <RouterLink className="no-underline text-gray-300 font-bold" to={"/services"}>
                Услуги
              </RouterLink>
            </li>
            <li className="p-2">
              <RouterLink className="no-underline text-gray-300 font-bold" to={"/contacts"}>
                Контакты
              </RouterLink>
            </li>
          </ul>
        </div>
        <div className="col-span-2 pr-4 pt-2">
          <RouterLink className="no-underline text-gray-300 font-bold" to={"/oferta"}>
              Пользовательское соглашение
          </RouterLink>
        </div>
      </div>

      <div className="flex flex-col max-w-[1240px] px-2 md-30 mx-auto justify-between sm:flex-row text-center text-gray-500">
        <p className="py-4">© nakrut.im, 2024</p>
        {/* Временно скроем соц сети */}
        {/* <div className="flex justify-between max-w-[200px] sm:w-[300px] pt-4 text-2xl">
          <FaFacebook />
          <FaInstagram />
          <FaTwitter />
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
