import { useEffect, useState } from "react"

// {
//   "order": 2687010
// }

export default function WosCreateOrderTimelineItem(props) {
  const [whatToDoText, setWhatToDoText] = useState('')
  const [whatHappeningText, setWhatHappeningText] = useState('')

  useEffect(() => {
    if (props.data.order) {
      setWhatToDoText('Подождите, ')
      setWhatHappeningText('ваш заказ с номером ' + props.data.order + ' выполняется...')
    } else {
      setWhatToDoText('Подождите, ')
      setWhatHappeningText('ваш заказ находится в обработке...')
    }
  }, [props])

  return (
    <div>
      {props.data.order ? (
        <p>
          ⌛ {whatToDoText}
          {whatHappeningText}
        </p>
      ) : (
        <p>
          ⌛ {whatToDoText}
          {whatHappeningText}
        </p>
      )}
    </div>
  )
}
