import axios from 'axios';

export default class Api {
    static getServices() {
        return axios.get(process.env.REACT_APP_URL + '/service/all?whitelist=true&perPage=1000')
    }
    static updateStatus(id) {
        return axios.get(process.env.REACT_APP_URL + `/order/update-status?billId=${id}`)
    }
    static servicePay(email, panelId, serviceId, json) {
        return axios.post(process.env.REACT_APP_URL + `/order/pay?email=${email}&panelId=${panelId}&serviceId=${serviceId}`, json)
    }
    static getArticles() {
        return axios.get(process.env.REACT_APP_URL + '/article/?&perPage=25')
    }
    static getArticle(id) {
        return axios.get(process.env.REACT_APP_URL + '/article/' + id)
    }
    static getSocialNetworks() {
        return axios.get(process.env.REACT_APP_URL + '/social/all')
    }
    static getOrderByBillId(billId) {
        return axios.get(process.env.REACT_APP_URL + '/order/single?billid='+billId)
    }
}
