import React from "react";
import { useEffect, useState } from "react";
import Api from "../models/Api";
import { useNavigate } from "react-router-dom";

const Articles = () => {
  const navigate = useNavigate()
  const [articles, setArticles] = useState([])

  useEffect(() => {
    Api.getArticles().then(data => {
      if (data.data.ok) {
        setArticles(data.data.data.data)
      } else {
        alert(data.data.data.error)
      }
    })
  }, [])

  return (
    <div className="w-full px-8 bg-zinc-200 py-28">
      {articles.length === 0 && 
        <div className="m-auto bg-zinc-100 p-8 border border-slate-300 rounded-xl shadow-xl mb-3">
          <div className="block">
            <div className="text-2xl">Следите за нашими новостями на этой странице</div>
            <ul className="pt-4 px-0 text-lg">
              <li>
                <p>Скоро тут появятся обновления :)</p>
              </li>
            </ul>
          </div>
        </div>
      }
      {articles.map(a => {
        return <div className="m-auto bg-zinc-100 p-8 border border-slate-300 rounded-xl shadow-xl mb-3">
          <div className="block hover:cursor-pointer" onClick={() => navigate("/article/" + a.id)}>
            <div className="text-2xl">{a.title}</div>
              <br/>
            {a.image && <img src={a.image} alt="" />}
            {/* <div className="pt-4 px-0 text-lg">{a.content.length > 100 ? a.content.substring(0, 100) + '...' : a.content}</div> */}
          </div>
        </div>
      })}
    </div>
  );
};

export default Articles;
