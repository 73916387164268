import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import dataService from './services/dataService';


const Init = () => {
  const [isInit, setInit] = useState(false);

  useEffect(()=>
  {
    dataService.preloadData().then(
      ()=>{
        dataService.preloadCategories().then(
          ()=>{
            setInit(true)
          }
        )
      }
    )
  },[])

  return(
  <BrowserRouter>
    {isInit && (<App />)}
  </BrowserRouter>
  )
}

ReactDOM.render(
  <Init/>,
  document.getElementById('root')
);

