import PaymentTimelineItem from "./PaymentTimelineItem";
import WosCreateOrderTimelineItem from "./WosCreateOrderTimelineItem";
import WosOrderStatusTimelineItem from "./WosOrderStatusTimelineItem";
import Api from "../../models/Api";
import { useEffect, useState } from "react";

export default function OrderTimeline(props) {
  const [order, setOrder] = useState();
  const [qiwiData, setQiwiData] = useState();
  const [wosCreateOrderData, setWosCreateOrderData] = useState();
  const [wosOrderStatusData, setWosOrderStatusData] = useState();

  useEffect(() => {
    Api.updateStatus(props.billId).finally(() => {
      Api.getOrderByBillId(props.billId).then((data) => {
        if (data.data.ok) {
          setOrder(data.data.data);
        }
      });
    });
  }, [props.billId]);

  useEffect(() => {
    if (order) {
      if (order.bill_info !== "") {
          setQiwiData(JSON.parse(order.bill_info));
      }
      if (order.panel_get_order_id_response !== ""){
          setWosCreateOrderData(JSON.parse(order.panel_get_order_id_response));
      }
        if (order.panel_order_status_response !== "") {

            setWosOrderStatusData(JSON.parse(order.panel_order_status_response));
        }
    }
  }, [order]);

  return (
    <ul>
      {/* <pre>{JSON.stringify(order, null, 2)}</pre> */}
      <li>
        <p>⌛ Запрашиваем информацию по заказу...</p>
      </li>
      <li>
        {qiwiData ? <PaymentTimelineItem data={qiwiData} /> : <div>⌛ Подождите, выполняется процесс оплаты...</div>}
      </li>
      <li>
        {qiwiData && qiwiData.status.value === "PAID" && <WosCreateOrderTimelineItem data={wosCreateOrderData} />}
      </li>
      <li>
        {wosCreateOrderData && wosCreateOrderData.order && <WosOrderStatusTimelineItem data={wosOrderStatusData} />}
      </li>
      {/* <p>Пожалуйста, обратитесь к администратору <a href="https://t.me/Yktvdk88">в Telegram.</a></p> */}
    </ul>
  );
}
