import React from "react";
import { useEffect, useState } from "react";
import dataService from "../services/dataService";
import Api from "../models/Api";
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/core";
import { Link as RouterLink, useParams } from "react-router-dom";
import Spinner from "./Spinner";

const Services = (props) => {
  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("all");

  const [isFormOpen, setIsOpenForm] = useState(false);
  const [isFormOpenInfo, setIsOpenFormInfo] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [schema, setSchema] = useState({});

  const [formData, setFormData] = useState({});
  const [formDataInfo, setFormDataInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  let { category } = useParams();

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    if (category !== undefined) setCurrentCategory(category);
    let data = dataService.getData();
    setCategories(dataService.getAllCategories());
    setServices(data);
  }, [category]);

    function isValidURL(url) {
        let urlPattern = /^(https?:\/\/)?\w+(\.\w+)+[/#?]?.*$/;
        return urlPattern.test(url);
    }

    function isValidEmail(email) {
        let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }

    function isAmountMTOne(amount) {
        return amount > 1;
    }

  function onFormSubmit(event) {

      if (!isAmountMTOne(event.formData.price)) {
          alert("Минимальная сумма оплаты - 1 рубль");
          return;
      }

      let total = Number(formData?.quantity) * Number(formData?.runs);

      if (total !== undefined && currentItem.max !== undefined && Number(currentItem.max) < Number(total)) {
          alert(`Количество больше максимального (${currentItem.max})`);
          return;
      }

        if (!isValidEmail(event.formData.email)) {
          alert(`Проверьте правильность почтового адреса!(${event.formData.email})`);
          return;
        }

        if (!isValidURL(event.formData.link)) {
          alert(`Проверьте правильность ссылки!(${event.formData.link})`);
          return;
        }

    setIsLoading(true);

    if (event.formData.expiry !== undefined) {
      let expiry = event.formData.expiry.split("-");
      event.formData.expiry = expiry[2] + "/" + expiry[1] + "/" + expiry[0];
    }

    if (!event.formData.isLaunch) {
      delete event.formData.runs;
      delete event.formData.interval;
    }

      Api
      .servicePay(event.formData.email, currentItem.panel_id, currentItem.service, event.formData)
      .then((data) => {
        let link = data?.data?.data?.link;
        if (link !== undefined) {
          window.open(link, "_blank");
          closeForm();
        } else {
          alert("Ошибка оплаты, пожалуйста обратитесь в поддержку сайта");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function onFormChange(e) {
    let newFormDataInfo = {"price": 0}

    if (e.formData.isLaunch !== undefined && e.formData.isLaunch && e.formData.runs !== undefined) {
      e.formData.total = String(Number(e.formData.quantity) * Number(e.formData.runs));
      if (currentItem.max !== undefined && Number(currentItem.max) < Number(e.formData.total)) {
        e.formData.total = `Количество больше максимального (${currentItem.max})`;
      }
        let calcPrice = (Number(currentItem.whitelist.new_rate) / 1000) * Number(e.formData.quantity) * Number(e.formData.runs)
        newFormDataInfo.price = parseFloat(calcPrice).toFixed(2);
        e.formData.price = calcPrice;
    } else {
        let calcPrice = (Number(currentItem.whitelist.new_rate) / 1000) * Number(e.formData.quantity)
        newFormDataInfo.price = parseFloat(calcPrice).toFixed(2);
        e.formData.price = calcPrice;
    }

    setFormData(e.formData);
    setFormDataInfo(newFormDataInfo);
  }

  function openForm(item) {
    closeForm();

    if (item.type === 'Price') {
        return
    }

    var schema = dataService.getFormData(item.type);
    // js object undefined
    // eslint-disable-next-line eqeqeq
    if (schema.properties.quantity != undefined) {
      schema.properties.quantity.minimum = Number(item.min);
      schema.properties.quantity.maximum = Number(item.max);
    }

    setSchema(schema);
    setCurrentItem(item);
    setIsOpenForm(true);
    if (item.type !== "Subscriptions") {
      setIsOpenFormInfo(true);
    }
  }

  function closeForm() {
    setSchema(null);
    setCurrentItem(null);
    setIsOpenForm(false);
    setIsOpenFormInfo(false);

    setFormData({});
    setSchema(null);
    setFormDataInfo({});
  }

  function handlePropogation(e) {
    e.stopPropagation();
  }

  return (
    <div className="flex flex-col min-h-screen">
      <div className="min-w-full mt-[70px] px-16 pt-[40px]">
        {categories.map((category, idx) => {
          return (
            <button
              className={
                "ml-2 p-2 mb-2 rounded-md" +
                (currentCategory.toLowerCase() === category.toLowerCase()
                  ? " bg-indigo-600 hover:text-gray-200 hover:bg-indigo-600"
                  : " bg-gray-200 text-gray-600 border-gray-400 hover:text-gray-600 hover:bg-gray-200")
              }
              onClick={() => {
                if (category.toLowerCase() === currentCategory.toLowerCase()) {
                  setCurrentCategory("all");
                } else {
                  setCurrentCategory(category);
                }
              }}
              key={idx + 1}
            >
              <img className="w-7 drop-shadow-lg" src={dataService.getImage(category)} alt="" />
            </button>
          );
        })}
      </div>

      <div className="">
        <div
          className="min-w-full grid 
              sm:grid-cols-1 sm:gap-1
              md:grid-cols-2 md:gap-2 
              lg:grid-cols-3 lg:gap-3 
              xl:grid-cols-4 xl:gap-4
              2xl:grid-cols-5 2xl:gap-5
              mt-[20px] mb-[25px] pl-[50px] pr-[50px]"
        >
          {services
            .filter((item) => {
              let categoryLower = item.category.toLowerCase();

              if (currentCategory === "all") {
                return true;
              }
              return categoryLower.indexOf(currentCategory.toLowerCase()) !== -1;
            })
            .map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="bg-[#fff] rounded-[10px] shadow-[0px 1px 2px #E1E3E5] border border-[#E1E3E5] divide-y grid shadow-md mb-2"
                >
                  <div className="pt-[40px] px-[25px] pb-[40px] grid place-content-stretch space-y-0" key={idx + 1}>
                    <div className="w-1/4 mx-auto">
                      <img src={dataService.getImage(item.category)} alt="" className="rounded-lg" />
                    </div>
                    <p className="text-center text-[16px] font-bold">{item.whitelist.new_name}</p>
                    <p className="text-center text-[#00153B] text-[14px]">{item.whitelist.content}</p>
                    <div>
                      <p className="text-center text-[#00153B] text-[16px] font-bold">
                        Цена: {Math.trunc(item.whitelist.new_rate * 100) / 100} руб. за 1000
                      </p>
                    </div>
                    <div className="mt-[25px]">
                      <button
                        className="w-full rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold"
                        onClick={() => openForm(item)}
                      >
                        Купить
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {isFormOpen && (
        <div style={{ zIndex: 1000 }} className="fixed flex w-100 h-100 items-center justify-center">
          <div
            className="flex flex-col px-[20px] mx-auto m-0
                      p-50 z-50 bg-white p-5 border-black border-2 rounded-2xl
                      mt-[20px] mb-[25px] pl-[50px] pr-[50px]"
            onClick={handlePropogation}
          >
            {/* <div className="text-xl text-center">Заполните форму:</div> */}
            {schema && (
              <div className="drop-shadow-lg">
                <Form
                  onChange={onFormChange}
                  formData={formData}
                  schema={schema}
                  validator={validator}
                  onSubmit={onFormSubmit}
                >
                  {isFormOpenInfo && (
                    <Form
                      formData={formDataInfo}
                      schema={dataService.getFormData("Price")}
                      validator={validator}
                      children={true}
                    />
                  )}
                  <div>
                    <button
                      className="mt-[25px] p-2 bg-indigo-600 rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold"
                      type="submit"
                      disabled={isLoading}
                    >
                      <div className="flex flex-row">
                      <div className="pr-1">Оплатить</div>
                      {isLoading && <Spinner/>}
                      </div>
                    </button>
                    <button
                      className="mt-[25px] ml-2 p-2 bg-indigo-600 rounded-[5px] py-[15px] px-[25px] text-[#fff] text-[14px] leading-[17px] font-semibold"
                      onClick={closeForm}
                    >
                      Отмена
                    </button>
                  </div>
                  <div className="pt-2">
                    <p className="text-sm">
                      Подтверждая заказ, Вы соглашаетесь с условиями{" "}
                      <RouterLink className="no-underline" to="/oferta">
                          пользовательского соглашения
                      </RouterLink>
                      .
                    </p>
                  </div>
                </Form>
              </div>
            )}
            {/* <button className="mt-[25px] p-2" onClick={closeForm}>
              X
            </button> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;
