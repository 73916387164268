import React from 'react'

const LandingAdCard = (props) => {
  return (
<div
    className="flex flex-col items-center text-center max-w-sm bg-zinc-100 border border-slate-300 rounded-xl overflow-hidden shadow-lg py-4 mb-2"
  >
    {/* <img className="w-1/3" src={props.imageSrc} alt="/" /> */}
    <p className='text-7xl'>{props.icon}</p>
    <div className="px-6 py-4">
      <div className="font-bold text-xl mb-4">{props.name}</div>
      <div
        className="grid grid-cols-1 gap-4 text-gray-700 text-base text-start"
      >
        <p>{props.description}</p>
      </div>
    </div>
   
  </div>
  )
}

export default LandingAdCard