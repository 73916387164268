// import About from './components/About';
import Contacts from "./components/Contacts";
import BillId from "./components/BillId";
import Footer from "./components/Footer";
import Services from "./components/Services";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import { Routes, Route } from "react-router-dom";
import Oferta from "./components/Oferta";
import Articles from "./components/Articles";
import Article from "./components/Article";
import ServicesLinks from "./components/ServicesLinks";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/">
          <Route index element={<Hero />} />
          <Route path="servicesLinks" element={<ServicesLinks />} />
          <Route path="services" element={<Services />} >
            <Route path=":category" element={<Services />} /> 
          </Route>
          <Route path="articles" element={<Articles />} />
          <Route path="article">
            <Route path=":articleId" element={<Article />} />
          </Route>
          <Route path="oferta" element={<Oferta />} />
          {/* About на данный момент скрыт, возможно понадобится позднее */}
          {/* <Route path="about" element={<About />} /> */}
          <Route path="contacts" element={<Contacts />} />
          <Route path="order">
            <Route path=":billId" element={<BillId />} />
          </Route>
          <Route path="*" element={<Hero />} />
        </Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
