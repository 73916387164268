import React from "react";

const Contacts = () => {
  return (
    <div className="w-full h-screen px-8 bg-zinc-200 flex flex-col justify-between">
      <div
        className="m-auto bg-zinc-100 p-8 
        border border-slate-300 rounded-xl shadow-xl"
      >
        <div className="block">
          <div className="text-2xl">Свяжитесь с нами по любым вопросам:</div>
          <ul className="pt-4 px-0 text-lg">
            <li>
              Напишите нам на <a href="mailto:support@nakrut.im">support@nakrut.im</a>
            </li>
            <li>
              Напишите нам в <a href="https://t.me/Yktvdk88">Telegram</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
