import React, { useState } from "react";

import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link as RouterLink } from "react-router-dom";

// import logoImg from "../assets/wos.png";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className="w-screen h-[80px] z-10 bg-zinc-200 fixed drop-shadow-lg">
      <div className="px-2 flex justify-between items-center w-full h-full">
        <div className="flex items-center">
          {/* Заказчик попросил временно не отображать логотип */}
          {/* <RouterLink to={"/"}>
              <img
                className="w-full h-full object-cover"
                src={logoImg}
                alt="/"
              />
            </RouterLink> */}
          <ul className="mb-0 hidden md:flex">
            {/* <li><Link to="home" smooth={true} duration={500}>Home</Link></li>
          <li><Link to="about" smooth={true} offset={-200} duration={500}>About</Link></li>
          <li><Link to="support" smooth={true} offset={-50} duration={500}>Support</Link></li>
          <li><Link to="platforms" smooth={true} offset={-100} duration={500}>Platforms</Link></li>
          <li><Link to="pricing" smooth={true} offset={-50} duration={500}>Pricing</Link></li> */}
            <li>
              <RouterLink className="no-underline text-gray-600 font-bold text-lg" to={"/"}>
                Главная
              </RouterLink>
            </li>
            <li>
              <RouterLink className="no-underline text-gray-600 font-bold text-lg" to={"/servicesLinks"}>
                Услуги
              </RouterLink>
            </li>
            {/* <li>
              <RouterLink to={"/about"}>О нас</RouterLink>
            </li> */}
            <li>
              <RouterLink className="no-underline text-gray-600 font-bold text-lg" to={"/articles"}>
                Статьи
              </RouterLink>
            </li>
            <li>
              <RouterLink className="no-underline text-gray-600 font-bold text-lg" to={"/contacts"}>
                Контакты
              </RouterLink>
            </li>
          </ul>
        </div>
        {/* <div className='hidden md:flex pr-4'>
          <button className='border-none bg-transparent text-black mr-4'>
            Sign In
          </button>
          <button className='bg-[#006EF5] rounded-[5px] px-8 py-3'>Sign Up</button>
        </div> */}
        <div className="md:hidden mr-4" onClick={handleClick}>
          {!nav ? <MenuIcon className="w-5" /> : <XIcon className="w-5" />}
        </div>
      </div>

      <ul className={!nav ? "hidden" : "absolute bg-zinc-200 w-full px-8"}>
      <li className="border-b-2 border-zinc-300 w-full font-bold">
          <RouterLink className="no-underline text-gray-600" to={"/"}>Главная</RouterLink>
        </li>
        <li className="border-b-2 border-zinc-300 w-full font-bold">
          <RouterLink className="no-underline text-gray-600" to={"/servicesLinks"}>Услуги</RouterLink>
        </li>
        <li className="border-b-2 border-zinc-300 w-full font-bold">
          <RouterLink className="no-underline text-gray-600" to={"/articles"}>Статьи</RouterLink>
        </li>
        <li className="border-b-2 border-zinc-300 w-full font-bold">
          <RouterLink className="no-underline text-gray-600" to={"/contacts"}>Контакты</RouterLink>
        </li>
        {/* <div className='flex flex-col my-4'>
            <button className='bg-transparent text-indigo-600 px-8 py-3 mb-4'>Sign In</button>
            <button className='px-8 py-3'>Sign Up</button>
        </div> */}
      </ul>
    </div>
  );
};

export default Navbar;
