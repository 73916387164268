import React from "react";

const Oferta = () => {
    return (
        <div className="flex flex-col min-h-screen pt-[100px] px-[20px]">
            <h2 className="font-bold mb-4">Пользовательское соглашение</h2>
            <span className="font-bold text-2xl">Основная часть</span>
            <p>
                При размещении заказа в сервисе «НакрутИм» (далее Сервис), на веб-сайте https://nakrut.im, вы подтверждаете, что согласны с данными Условиями предоставления услуг (далее Условия).
                Мы оставляем за собой право на изменение Условий без предварительного уведомления. Перед оформлением заказа вы должны убедиться, что ознакомились с последними изменениями Условий.
                Услуги Сервиса предоставляются в соответствии с условиями и соглашениями, которые вы принимали при работе с Telegram, Вконтакте, YouTube, TikTok.
                Информация о скорости выполнения заказов указана в описании каждой услуги. Сервис не гарантирует скорость выполнения заказа. Мы лишь предоставляем клиенту информацию о средней скорости, с которой выполнялось большинство заказов по данной услуге. Вы можете в любой момент прервать выполнение заказа обратившись в техподдержку. Деньги за невыполненную часть заказа будут возвращены на те же реквизиты и в той же валюте, с которых производилась оплата.
                Сервис старается предоставить те услуги, которые необходимы клиенту. По этой причине мы оставляем за собой право самостоятельно изменить выбранную услугу на аналогичную, если посчитаем это необходимым для своевременного завершения заказа.
            </p>
            <span className="font-bold text-2xl">Порядок оплаты</span>
            <p>
                Оплата заказов осуществляется после оформления заказа в Сервисе, до начала его выполнения.  Цены на услуги Сервиса могут быть изменены в любой момент без предварительного уведомления. В случае изменения цен, политика оплаты и возврата средств остается в силе.

                К оплате принимаются платежные карты: VISA, MasterCard, Maestro, МИР. При оплате заказа банковской картой, обработка платежа происходит на авторизационной странице банка, где Вам необходимо ввести данные Вашей банковской карты:
                1. Тип карты
                2. Номер карты,
                3. Срок действия карты
                4. Имя держателя карты (латинскими буквами, точно также как указано на карте)
                5. CVC2/CVV2 код
                Если Ваша карта подключена к услуге 3D-Secure, Вы будете автоматически переадресованы на страницу банка, выпустившего карту, для прохождения процедуры аутентификации. Информацию о правилах и методах дополнительной идентификации уточняйте в Банке, выдавшем Вам банковскую карту.

            </p>
            <span className="font-bold text-2xl">Отказ от ответственности</span>
            <p>
                Сервис не несет ответственности за какой-либо ущерб, блокировку, удаление информации на аккаунте или самого аккаунта Telegram, Вконтакте, YouTube, TikTok.
            </p>
            <span className="font-bold text-2xl">Услуги</span>
            <p>
                Сервис предназначен прежде всего для увеличения численных показателей вашего аккаунта или сообщества Telegram, Вконтакте, YouTube, TikTok. Мы не гарантируем, что новые подписчики будут проявлять какую-либо активность. Мы гарантируем лишь то, что вы получите то количество подписчиков, просмотров, лайков или голосов, за которое заплатили.
            </p>
            <span className="font-bold text-2xl">Политика возврата денежных средств</span>
            <p>
                Выполненный заказ не подлежит отмене или возврату. Вы получите возврат денежных средств, если заказ не будет выполнен. Возврат осуществляется в течение 3 (трех) рабочих дней на те же реквизиты и в той же валюте, с которых производилась оплата. Без дополнительной комиссии.
                Заказы, содержащие информацию, нарушающую правила и условия Telegram, Вконтакте, YouTube, TikTok, либо законодательство РФ, могут быть аннулированы без возврата денежных средств. Внимательно проверяйте заказы перед отправкой.
                Мошенническая деятельность, такая как использование украденных данных кредитных карт или аккаунтов электронных платежных систем, приведет к блокировке вашей учетной записи и аннулированию заказов.
            </p>
            <span className="font-bold text-2xl">Политика конфиденциальности</span>
            <p>
                Мы серьезно относимся к вашей конфиденциальности и принимаем все меры для защиты вашей личной информации и аккаунта. Любая полученная личная информация будет использована только для выполнения заказа. Мы обязуемся не передавать полученную личную информацию третьим лицам. Вся информация зашифрована и хранится на защищенных серверах.
            </p>
            <span className="font-bold text-2xl">Политика информационной безопасности</span>
            <p>
                Оплатить услуги Сервиса можно банковскими картами Visa, Master Card, МИР или через платежные системы ЮMoney, SberPay. Чтобы оплатить покупку, вы будете перенаправлены на сервер платежной системы "ЮKassa", на котором нужно ввести необходимые данные. При оплате банковской картой безопасность платежей гарантирует процессинговый центр "ЮKassa".
                "ЮKassa" обладает подтвержденным сертификатом соответствия требованиям стандарта PCI DSS в части хранения, обработки и передачи данных держателей карт. Стандарт безопасности банковских карт PCI DSS поддерживается международными платежными системами, включая MasterCard и Visa
                Ваши конфиденциальные данные, необходимые для оплаты (реквизиты карты, регистрационные данные и др.), не поступают в Сервис — их обработка производится на стороне процессингового центра "ЮKassa" и полностью защищена.
            </p>
            <span className="font-bold text-2xl">Наименование, контактные данные</span>
            <p>
                Володин Владимир Владимирович, ИНН 143531914341<br/>
                <br/>
                <b>support@nakrut.im</b>
            </p>
        </div>
    );
};

export default Oferta;
