import { useEffect, useState } from "react";

// {
//   "charge": "49.635",
//   "status": "Completed",
//   "remains": "0",
//   "currency": "RUB",
//   "start_count": "56"
// }

export default function WosOrderStatusTimelineItem(props) {
  const [whatToDoText, setWhatToDoText] = useState("");
  const [whatHappeningText, setWhatHappeningText] = useState("");

  useEffect(() => {
    if (props.data.status === "Completed") {
      setWhatToDoText("Поздравляем! ");
      setWhatHappeningText("Ваш заказ успешно выполнен.");
    } else {
      setWhatToDoText("Подождите, ");
      setWhatHappeningText("ваш заказ находится в статусе выполнения...");
    }
  }, [props]);

  return (
    <div>
      {props.data.status === "Completed" ? (
        <p>
          ✅ {whatToDoText}
          {whatHappeningText}
        </p>
      ) : (
        <p>
          ⌛ {whatToDoText}
          {whatHappeningText}
        </p>
      )}
    </div>
  );
}
