import React from "react";

import bgImg from "../assets/socials.svg";
import LandingAdCard from "./LandingAdCard";
import { useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import dataService from "../services/dataService";

const Hero = () => {
    const navigate = useNavigate();
    const categories = dataService.getAllCategories();


  return (
    <div>
      <div className="w-full h-screen px-8 md:h-[700px] bg-zinc-200 flex flex-col justify-between">
        <div className="grid md:grid-cols-2 md:max-w-[80%] m-auto">
          <div className="flex flex-col justify-center md:items-start w-full px-2 py-8">
            <p className="text-2xl">Мы обеспечим Вам</p>
            <h1 className="py-3 text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold">
              <span className="text-indigo-600">Быстрое🔥</span>Продвижение
            </h1>
            <p className="text-2xl">В Социальных Сетях</p>

            <button
              className="py-3 px-6 sm:w-[60%] my-4 rounded-[5px]"
              onClick={() => {
                navigate("/services");
              }}
            >
              Начать!
            </button>
          </div>
          <div className="flex items-center">
            <img className="w-full" src={bgImg} alt="" />
          </div>
            <div className="hidden md:block">
              <div className="absolute left-1/2 transform -translate-x-1/2 max-w-[600px] min-w-[550px] mx-auto">
                <div className="justify-center -top-8 py-8  bg-zinc-100 border border-slate-300 rounded-xl text-center shadow-x">
                  <p className="font-bold text-2xl mb-4 text-gray-800">Наши услуги</p>
                  <div className="flex justify-center flex-wrap px-8">
                    {categories && 
                    (categories.map((category) => {
                      return (
                        <RouterLink to={`/services/${category}`} key={category}><img className="w-12 pr-2 drop-shadow-lg" src={dataService.getImage(category)} alt=""/></RouterLink>
                      );})
                    )}
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div>
        <div className="w-full my-32">
          <div className="max-w-[1240px] mx-auto">
            <div className="text-center mb-12">
              <h2 className="text-5xl font-bold">Преимущества нашего сервиса</h2>
            </div>
            <div
              className="px-8 grid 
            sm:grid-cols-1 sm:gap-1
            md:grid-cols-3 md:gap-3 
            lg:grid-cols-3 lg:gap-3 
            xl:grid-cols-3 xl:gap-3
            2xl:grid-cols-3 2xl:gap-3
        "
            >
              <LandingAdCard
                name="Быстрое исполнение"
                description="Начало исполнения заказа не заставит долго ждать. Заказ начнет исполняться максимально быстро на сколько это возможно."
                imageSrc=""
                icon="⏰"
              />
              <LandingAdCard
                name="Оперативная поддержка"
                description="Поддержка работает с 8:00 - 20:00 по Москве. Если у Вас есть вопрос напишите нам и мы будем рады Вам помочь!"
                imageSrc=""
                icon="📞"
              />
              <LandingAdCard
                name="Высокое качество"
                description="Лучшее качество услуг на рынке по доступным ценам. Мы постоянно следим за качеством наших услуг - нам важно, чтобы Вы были довольны результатом."
                imageSrc=""
                icon="⭐"
              />
              <LandingAdCard
                name="Конфиденциально"
                description="Нам не нужны пароли и рекомендуем не вводить пароли от Ваших социальных сетей на сторонних сайтах . Мы не разглашаем информацию третьим лицам о выполненных заказах."
                imageSrc=""
                icon="🎭"
              />
              <LandingAdCard
                name="Наши гарантии"
                description="Мы стараемся, чтобы Вы получили максимальное удовольствие от пользования услуг нашего сервиса! Если Вас что-то не устроило пожалуйста сообщите нам в поддержку, обратная связь для нас очень важна."
                imageSrc=""
                icon="✅"
              />
              <LandingAdCard
                name="Безопасные платежи"
                description="Распространенные методы оплаты: Apple pay, Google pay, оплата картой и множество других способов оплаты. Мы уверены, что Вы найдете подходящий."
                imageSrc=""
                icon="💳"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
