class DataServiceConstants {
    AllCategories = [
        'Instagram',
        'Telegram',
        'YouTube',
        'ВКонтакте',
        'TikTok',
        'Facebook',
        'Twitter',
        'Twitch',
        'Индивидуальные услуги'
    ]
    categoryDescriptions = {
        'instagram': 'Одна из самых популярных площадок, объединяющая миллионы пользователей. Здесь успешно продвигаются фотографии, видеоролики, творческие проекты. Дополнительно выступает мировой торговой площадкой. Для привлечения потенциальных клиентов со всего мира воспользуйтесь накруткой. Наш сервис планомерно увеличивает показатели, что исключает блокировку аккаунта в подозрении на сомнительную активность.', 
        'telegram': 'Telegram – одна из молодых социальных сетей, чья популярность стремительно растет. Помимо общения и просмотра каналов, здесь можно активно продвигать собственную продукцию, привлекать рекламодателей, реализовывать творческие идеи. Благодаря широкому функционалу, Телеграм одинаково подходит в качестве мессенджера и платформы для развития бизнеса. Но чтобы канал стал узнаваем и приносил доход, нужно привлечение активной аудитории. Наш сервис обеспечит эффективное продвижение путём накрутки необходимых показателей.',
        'youtube': 'Самая популярная видеоплатформа, которая объединяет ролики, клипы, фильмы со всего мира. Так как в условиях высокой конкуренции попасть в лидирующие позиции крайне сложно, мы помогаем накрутить видео. Эти показатели позволяют продвинуть контент с привлечением новой аудитории. Постепенное увеличение показателей выводит видео в рекомендуемые, появляясь у большого количества пользователей.',
        'вконтакте': 'Второй по популярности сайт. Согласно статистике, в этой соцсети ежедневно регистрируются тысячи новых пользователей, что расширяет охват аудитории. Для продвижения личной страницы или группы ВК имеет значение накрутка предлагаемых нами показателей. Эти ресурсы быстро распространяют контент, привлекая ЦА. Региональных ограничений нет. Контент автора стремительно набирает популярность среди пользователей России и стран СНГ.',
        'tiktok': 'Самая молодая площадка, завоевавшая популярность большой аудитории. Стремительный рост регистрации новых участников позволяет аккаунту раскрутиться в короткие сроки путем нашей накрутки. Особенность платформы – популяризация коротких видео с наложенной музыкой. От автора не требуется опыта съемок и видеомонтажа. Однако самостоятельно продвинуться в условиях высокой конкуренции сложно. Наш сервис привлекает аудиторию, повышая скорость завоевания лояльности публики.',
        'facebook': 'Крупнейшая социальная сеть, объединяющая людей со всего мира. Здесь можно найти любую полезную информацию, стать участником тематических групп, обрести новых знакомых. Повышение популярности страницы или сообщества достигается накруткой. Наш сервис задействует также и реальных пользователей (без привлечения ботов). Благодаря этому, продвижение имеет естественный характер.',
        'twitter': 'Американский сервис микроблогов и социальная сеть, в которой пользователи публикуют сообщения, известные как «твиты», и взаимодействуют с ними. Пользователи взаимодействуют с «Твиттером» через браузер, мобильное приложение или через API. До апреля 2020 года сервисы были доступны через SMS.',
        'twitch': 'Видеостриминговый сервис, специализирующийся на тематике компьютерных игр, в том числе трансляциях геймплея и киберспортивных турниров. Видео на платформе Twitch можно просматривать как в реальном времени, так и по запросу. Twitch принадлежит Twitch Interactive — дочерней компании Amazon.',
        'индивидуальные услуги': 'Видеостриминговый сервис, специализирующийся на тематике компьютерных игр, в том числе трансляциях геймплея и киберспортивных турниров. Видео на платформе Twitch можно просматривать как в реальном времени, так и по запросу. Twitch принадлежит Twitch Interactive — дочерней компании Amazon.'
    }

    DefaultFormData = {
        "type": "object",
        "properties": {
            "email": {
                "title": "Электронная почта",
                "type": "string"
            },
            "link": {
                "title": "Ссылка",
                "type": "string"
            },
            "quantity": {
                "title": "Количество",
                "type": "integer",
                "minimum": 10,
                "maximum": 100
            },
            // "isLaunch": {
            //     "title": " Запуск по расписанию",
            //     "type": "boolean",
            //     "enum": [true, false],
            //     "default": false
            // }
        },
        // "allOf": [
        //     {
        //       "if": {
        //         "properties": {
        //           "isLaunch": {
        //               "const": true
        //           }
        //         }
        //       },
        //       "then": {
        //         "properties": {
        //             "runs": {
        //                 "title": "Запуски",
        //                 "type": "integer"
        //             },
        //             "interval": {
        //                 "title": "Интервал (минуты)",
        //                 "type": "integer"
        //             },
        //             "total": {
        //                 "title": "Общее количество",
        //                 "type": "string",
        //                 "readOnly": true
        //             }
        //         },
        //         "required": [
        //             "runs",
        //             "interval"
        //         ],
        //       }
        //     }
        //
        //   ],
        "required": [
            "email",
            "link",
            "quantity",
        ],
    }
    PackageFormData = {
        "type": "object",
        "properties": {
            "email": {
                "title": "Электронная почта",
                "type": "string"
            },
            "link": {
                "title": "Ссылка",
                "type": "string"
            },
            "quantity": {
                "title": "Количество",
                "type": "integer",
                "minimum": 10,
                "maximum": 100
            }
        },
        "required": [
            "quantity",
            "email",
            "link"
        ],
    }
    CustomCommentsFormData = {
        "type": "object",
        "properties": {
            "email": {
                "title": "Электронная почта",
                "type": "string"
            },
            
            "link": {
                "title": "Ссылка",
                "type": "string"
            },
            "quantity": {
                "title": "Количество",
                "type": "integer",
                "minimum": 10,
                "maximum": 100
            },
            "comments": {
                "title": "Комментарий",
                "type": "string"
            }
        },
        "required": [
            "quantity",
            "email",
            "link",
            "comments"
        ],
    }
    
    CommentLikesFormData = {
        "type": "object",
        "properties": {
            "email": {
                "title": "Электронная почта",
                "type": "string"
            },
            "link": {
                "title": "Ссылка",
                "type": "string"
            },
            "quantity": {
                "title": "Количество",
                "type": "integer",
                "minimum": 10,
                "maximum": 100
            },
            "username": {
                "title": "Логин",
                "type": "string"
            }
        },
        "required": [
            "email",
            "link",
            "quantity",
            "username"
        ],
    }
    
    SubscriptionsFormData = {
        "type": "object",
        "properties": {
            "email": {
                "title": "Электронная почта",
                "type": "string"
            },
            "username": {
                "title": "Логин",
                "type": "string"
            },
            "quantity": {
                "title": "Количество",
                "type": "integer",
                "minimum": 10,
                "maximum": 100
            },
            "delay": {
                "title": "Задержка (минуты)",
                "type": "integer",
                "enum": [0, 5, 10, 15, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 360, 420, 480, 540, 600]
            },
            "isAddSettings": {
                "title": "Дополнительные настройки",
                "type": "boolean",
                "enum": [true, false],
                "default": false
            },
        },
        "allOf": [
            {
              "if": {
                "properties": {
                  "isAddSettings": {
                    "const": true
                  }
                }
              },
              "then": {
                "properties": {
                    "posts": {
                        "title": "Количество новых публикаций",
                        "type": "integer"
                    },
                    "old_posts": {
                        "title": "Количество старых публикаций",
                        "type": "integer"
                    },
                    "expiry": {
                        "title": "Истечение срока",
                        "type": "string",
                        "format": "date"
                    }
                },
                "required": [
                    "posts",
                    "old_posts",
                    "expiry"
                ],
              }
            }
        
          ],
        "required": [
            "quantity",
            "email",
            "username",
            "delay",
        ],
    }
    
    PriceFormData = {
        "type": "object",
        "properties": {
            "price": {
                "title": "Стоимость (руб.)",
                "type": "integer",
                "readOnly": true
            }
        }
    }
}



export default new DataServiceConstants();