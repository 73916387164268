import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../models/Api";

const Article = () => {
  let { articleId } = useParams();
  const [article, setArticle] = useState()

  useEffect(() => {
    Api.getArticle(articleId).then(data => {
      if (data.data.ok) {
        setArticle(data.data.data)
      } else {
        alert(data.data.error)
      }
    })
  }, [articleId])

  return (
    <div className="w-full px-8 bg-zinc-200 py-28">
      {article ?
        <div className="m-auto bg-zinc-100 p-8 border border-slate-300 rounded-xl shadow-xl mb-3">
          <div className="block">
            <div className="text-2xl">{article.title}</div>
            {article.image && <img src={process.env.REACT_APP_URL + article.image} alt="" />}
            <div className="pt-4 px-0 text-lg" dangerouslySetInnerHTML={ { __html: article.content } }/>
          </div>
        </div>
      :
        <div>Загрузка...</div>
      }
    </div>
  );
};

export default Article;
