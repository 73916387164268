import React from "react";
import { useParams } from "react-router-dom";
import OrderTimeline from "./OrderTimeline";

const BillId = () => {
  let { billId } = useParams();

  return (
    <div className="w-full px-8 md:min-h-[700px] bg-zinc-200 flex flex-col justify-between">
      <div className="m-auto">
        <div className="bg-gray-50 rounded-xl">
          <div className="my-[10rem] max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
            <OrderTimeline billId={billId}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillId;
